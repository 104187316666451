import React, { useEffect, useState } from 'react';
import useStyles from './style';
import ContainerAuth from '../../components/ContainerAuth';
import LeftContainerFlow from '../../components/LeftContainerFlow';
import HeadAuth from '../../components/HeadAuth';
import SignInForm from './components/SignInForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../hooks/useReduxState';
import {
  checkToken,
  mailConfirmation,
  signIn
} from '../../../../stories/actions/auth';
import { getBusinessModel } from '../../../../utils/function/getBusinessModel';
import notify from '../../../../utils/notify';
import { businessInviteConfirmation } from '../../../../stories/actions/business';

type Props = {};

const SignIn = (props: Props) => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [connected, setConnected] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingConfirmation, setLoadingConfirmation] =
    useState<any>(undefined);
  const [captcha, setCaptcha] = useState(false);

  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { auth } = useReduxState();

  useEffect(() => {
    const code: any = params['*'] || '';

    if (code.length === 90) {
      if (loadingConfirmation === false) {
        setLoadingConfirmation(true);

        const cb = {
          success: () => {
            setTimeout(() => {
              setLoadingConfirmation(false);
            }, 400);

            navigate(`/sign-in`);
          },
          error: () => {
            setTimeout(() => {
              setLoadingConfirmation(false);
            }, 400);

            navigate(`/sign-in`);
          }
        };

        dispatch(mailConfirmation({ code }, cb));
      } else {
        if (loadingConfirmation === undefined) {
          setLoadingConfirmation(false);
        }
      }
    } else {
      if (code.includes('businessCode')) {
        const businessCode = code.split('businessCode=')[1];

        if (businessCode) {
          if (loadingConfirmation === false) {
            setLoadingConfirmation(true);

            const cb = {
              success: () => {
                setTimeout(() => {
                  setLoadingConfirmation(false);
                }, 400);

                navigate(`/sign-in`);
              },
              error: () => {
                setTimeout(() => {
                  setLoadingConfirmation(false);
                }, 400);

                navigate(`/sign-in`);
              }
            };

            dispatch(businessInviteConfirmation({ code: businessCode }, cb));
          } else {
            if (loadingConfirmation === undefined) {
              setLoadingConfirmation(false);
            }
          }
        }
      } else {
        navigate(`/sign-in`);
      }
    }
  }, [loadingConfirmation]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (email === '') {
      return notify.error('O campo de e-mail é obrigatório.');
    }

    if (password === '') {
      return notify.error('O campo de senha é obrigatório.');
    }

    if (!loading && captcha) {
      setLoading(true);

      const cb = {
        success: (user?: any, firstAccess = false) => {
          setCaptcha(false);

          const paymentCheckout = localStorage.getItem('PAYMENT_CHECKOUT');

          if (
            paymentCheckout &&
            paymentCheckout !== '' &&
            paymentCheckout !== 'inactive'
          ) {
            localStorage.getItem('');

            navigate(paymentCheckout);
          } else {
            const businessModel = getBusinessModel();

            if (!user?.isSubscriber && businessModel !== 'freemium') {
              const freeTrial = user?.detail?.options?.freeTrial;

              if (freeTrial) {
                return navigate('/plans');
              }
            }

            if (firstAccess) {
              navigate(`/home?product_tour_id=479884`);

              // navigate(`/home`);

              if (user) {
                // @ts-ignore
                window.intercomSettings = {
                  api_base: 'https://api-iam.intercom.io',
                  app_id: 'awj7l5s9',
                  name: user.name, // Nome completo
                  email: user.email // Endereço de e-mail
                };
              }

              // @ts-ignore
              window.Intercom('boot', {
                app_id: 'awj7l5s9'
              });
            } else {
              navigate(`/home`);
            }
          }

          setLoading(false);
        },
        error: () => {
          setLoading(false);
        }
      };

      dispatch(signIn({ email, password, connected }, cb));
    }
  };

  const handleChange = ({ target }: any) => {
    setConnected(target.checked);
  };

  return (
    <>
      <div className={classes.root}>
        <HeadAuth
          title="Clarice.ai - Login"
          description="Seus dados estão seguros com a gente, sob a proteção da LGPD. Acesse os termos de uso e as políticas de privacidade."
        />
        <ContainerAuth>
          <LeftContainerFlow />

          <SignInForm
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            connected={connected}
            loading={loading}
            handleChange={handleChange}
            onSubmit={onSubmit}
            captcha={captcha}
            setCaptcha={setCaptcha}
          />
        </ContainerAuth>
      </div>
    </>
  );
};

export default SignIn;
