import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { AddRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import useStyles from './style';
import { useReduxState } from '../../../../../../../../hooks/useReduxState';
import { GlobalContext } from '../../../../../../../../utils/context/GlobalContext';
import { ThemeContext } from '../../../../../../../../utils/context/ThemeContext';
import SidebarItemCollapse from '../../../SidebarItemCollapse';
import SidebarItem from '../../../SidebarItem';
import routes from '../../../../../../../../router/function/generateRoute/routes';
import { signOut } from '../../../../../../../../stories/actions/auth';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { GoogleLogout } from 'react-google-login';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import { isBetaUser } from '../../../../../../../../utils/function/isBetaUser';

type ModalAccountProps = {
  modalVisible: boolean;
  setModalVisible: (_id: boolean) => void;
  toggle?: any;
};

const ModalAccount = ({
  modalVisible,
  setModalVisible,
  toggle
}: ModalAccountProps) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const ctx = useContext(GlobalContext);

  const dispatch = useAppDispatch();

  const { user } = useReduxState();

  const { projects } = useReduxState();

  const { allProjects } = projects;

  const preventDefault = (event: React.SyntheticEvent) => {
    event.preventDefault();

    setModalVisible(!modalVisible);

    toggle();

    navigate(`/projects`);
  };

  const listRef: any = useRef(null);

  const disableStyle = themeMode.mode === 'light' ? `#000` : '#fff';

  const isBusiness =
    user.isSubscriber &&
    ['Assinatura Mensal | Business', 'Assinatura Anual | Business'].includes(
      user?.options?.plan?.type
    );

  const isCollaborators = isBetaUser(user, 'clarice-collaborators');

  useEffect(() => {
    function handleClickOutside(event) {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setTimeout(() => {
          setModalVisible(false);
        }, 200);
      }
    }

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [listRef]);

  const getTop = () => {
    if (isBusiness && isCollaborators) {
      return '-220px';
    }

    if (isCollaborators) {
      return '-180px';
    }

    if (isBusiness) {
      return '-180px';
    }

    return '-155px';
  };

  const getHeight = () => {
    if (isBusiness && isCollaborators) {
      return '220px';
    }

    if (isCollaborators) {
      return '180px';
    }

    if (isBusiness) {
      return '180px';
    }

    return '145px';
  };

  return (
    <>
      <Card
        className={classes.root}
        // onClick={() => setModalVisible(!modalVisible)}
        ref={listRef}
        sx={{
          fontFamily: 'Inter',
          backgroundColor: themeMode.mode === 'light' ? `#fff` : '#252525',
          boxShadow: 'none',
          top: getTop(),
          height: getHeight()
        }}
      >
        <List className={classes.list} subheader={<li />}>
          <ListItemButton
            onClick={() => {
              navigate('/account/profile');

              setModalVisible(!modalVisible);

              toggle();
            }}
            // component={Link}
            // to={item.path}
            sx={{
              minWidth: '190px',
              position: 'relative',
              color: disableStyle,
              fontWeight: '500',
              fontSize: '14px',
              paddingY: '10px',
              paddingX: '10px',
              display: 'flex',
              alignItems: 'center',
              transition: 'none',
              '&:hover': {
                backgroundColor:
                  themeMode.mode === 'light'
                    ? `${theme.palette.background.lighterGrey}`
                    : `${theme.palette.background.lighterGrey}`
              }
            }}
          >
            Perfil
          </ListItemButton>
          {isBusiness && (
            <ListItemButton
              onClick={() => {
                navigate('/account/business');

                setModalVisible(!modalVisible);

                toggle();
              }}
              // component={Link}
              // to={item.path}
              sx={{
                minWidth: '190px',
                position: 'relative',
                color: disableStyle,
                fontWeight: '500',
                fontSize: '14px',
                paddingY: '10px',
                paddingX: '10px',
                display: 'flex',
                alignItems: 'center',
                transition: 'none',
                '&:hover': {
                  backgroundColor:
                    themeMode.mode === 'light'
                      ? `${theme.palette.background.lighterGrey}`
                      : `${theme.palette.background.lighterGrey}`
                }
              }}
            >
              Meu Negócio
            </ListItemButton>
          )}

          {isCollaborators && (
            <ListItemButton
              onClick={() => {
                navigate('/backoffice');

                setModalVisible(!modalVisible);

                toggle();
              }}
              // component={Link}
              // to={item.path}
              sx={{
                minWidth: '190px',
                position: 'relative',
                color: disableStyle,
                fontWeight: '500',
                fontSize: '14px',
                paddingY: '10px',
                paddingX: '10px',
                display: 'flex',
                alignItems: 'center',
                transition: 'none',
                '&:hover': {
                  backgroundColor:
                    themeMode.mode === 'light'
                      ? `${theme.palette.background.lighterGrey}`
                      : `${theme.palette.background.lighterGrey}`
                }
              }}
            >
              Backoffice
            </ListItemButton>
          )}
          <ListItemButton
            onClick={() => {
              navigate('/account/credits-billings');

              setModalVisible(!modalVisible);

              toggle();
            }}
            // component={Link}
            // to={item.path}
            sx={{
              minWidth: '190px',
              position: 'relative',
              color: disableStyle,
              fontWeight: '500',
              fontSize: '14px',
              paddingY: '10px',
              paddingX: '10px',
              display: 'flex',
              alignItems: 'center',
              transition: 'none',
              '&:hover': {
                backgroundColor:
                  themeMode.mode === 'light'
                    ? `${theme.palette.background.lighterGrey}`
                    : `${theme.palette.background.lighterGrey}`
              }
            }}
          >
            Créditos e Cobrança
          </ListItemButton>
          <GoogleLogout
            key={1}
            onLogoutSuccess={() => {
              dispatch(signOut(user));

              // navigate(`/sign-in`);

              window.location.href = '/sign-in';
            }}
            onFailure={() => {
              dispatch(signOut(user));

              // navigate(`/sign-in`);

              window.location.href = '/sign-in';
            }}
            clientId={
              '389449520627-60p78dhde3e6dd54bu0na38i9n1u36f5.apps.googleusercontent.com'
            }
            render={renderProps => (
              <ListItemButton
                // onClick={() => {
                //   // navigate('/account/edit-account');
                //
                //   toggle();
                // }}
                // component={Link}
                // to={item.path}
                sx={{
                  minWidth: '190px',
                  position: 'relative',
                  color: disableStyle,
                  fontWeight: '500',
                  fontSize: '14px',
                  paddingY: '10px',
                  paddingX: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  transition: 'none',
                  '&:hover': {
                    backgroundColor:
                      themeMode.mode === 'light'
                        ? `${theme.palette.background.lighterGrey}`
                        : `${theme.palette.background.lighterGrey}`
                  }
                }}
                {...renderProps}
              >
                Sair
              </ListItemButton>
            )}
          />
        </List>
      </Card>
    </>
  );
};

export default ModalAccount;
