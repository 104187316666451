import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../../../../hooks/useReduxState';
import notify from '../../../../../../../utils/notify';
import {
  backofficeCreateBusiness,
  backofficeUpdateBusiness
} from '../../../../../../../stories/actions/backoffice';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Switch,
  TextField,
  useTheme
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import useStyles from './style';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import BusinessUsers from './components/BusinessUsers';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

const BusinessUpdate = () => {
  const classes: any = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const navigate = useNavigate();

  const params = useParams();

  const dispatch = useAppDispatch();

  const { backoffice } = useReduxState();

  const [loading, setLoading] = useState(false);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const startDate = new Date();

  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 14);

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const [form, setForm] = useState<any>({
    name: '',
    licenses: 5,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    users: [],
    sharedBrand: false
  });

  useEffect(() => {
    const id = params.id;

    if (backoffice?.loaded && !form._id) {
      if (!id) {
        return navigate('/backoffice');
      }

      const business = backoffice.businesses?.find(
        (business: any) => business._id === params.id
      );

      if (business && !loading) {
        const startDate = business?.subscription?.period?.start?.split('T')[0];
        const endDate = business?.subscription?.period?.end?.split('T')[0];

        setForm({
          _id: business._id,
          name: business.name,
          licenses: business.licenses || 5,
          startDate,
          endDate,
          users: business.users || [],
          sharedBrand: business?.options?.sharedBrand || false
        });
      } else {
        return navigate('/backoffice');
      }
    }
  }, [backoffice?.businesses, params]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    if (type === 'sharedBrand') {
      setForm({ ...form, [type]: e.target.checked });
      return;
    }

    setForm({ ...form, [type]: e.target.value });
  };

  const onSubmit = async (e: any) => {
    if (e) e.preventDefault();

    if (form.name === '') {
      return notify.error('Preencha o nome da empresa!');
    }

    if (form.licenses === '') {
      return notify.error('Preencha a quantidade de licenças!');
    }

    if (form.startDate === '') {
      return notify.error('Preencha a data inicial!');
    }

    if (form.endDate === '') {
      return notify.error('Preencha a data final!');
    }

    setLoading(true);

    const cb = {
      success: () => {
        setLoading(false);

        navigate('/backoffice');
      },
      error: () => {
        setLoading(false);
      }
    };

    dispatch(backofficeUpdateBusiness(form, cb));
  };

  const onRefresh = business => {
    setForm({
      ...form,
      users: business.users || []
    });
  };

  console.log('BACKOFFICE!');

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={12}>
            <div className={classes.contentContainer}>
              <div className={classes.formContainer}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <IconButton
                    onClick={() => {
                      navigate('/backoffice');
                    }}
                    sx={{
                      marginRight: '10px'
                    }}
                  >
                    <ArrowBackIcon />
                  </IconButton>

                  <p className={classes.title}>Editar Empresa</p>
                </div>
                <form onSubmit={onSubmit} noValidate autoComplete="off">
                  <Box
                    sx={{
                      // width: 400,
                      backgroundColor:
                        themeMode.mode === 'light'
                          ? `${theme.palette.background.paper}`
                          : `${theme.palette.background.default}`,
                      borderRadius: '15px',
                      // padding: '0rem 1rem',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem',
                      marginTop: '40px'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2rem'
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        label="Nome da empresa"
                        variant="outlined"
                        color={
                          themeMode.mode === 'light' ? 'primary' : 'secondary'
                        }
                        value={form.name}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleChange(e, 'name')}
                        sx={{
                          fontFamily: 'Inter'
                        }}
                        InputLabelProps={{ shrink: true }}
                      />

                      <TextField
                        id="outlined-basic"
                        label="Quantidade de licenças"
                        variant="outlined"
                        color={
                          themeMode.mode === 'light' ? 'primary' : 'secondary'
                        }
                        value={form.licenses}
                        type="number"
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleChange(e, 'licenses')}
                        sx={{
                          fontFamily: 'Inter'
                        }}
                        InputLabelProps={{ shrink: true }}
                      />

                      <TextField
                        id="outlined-basic"
                        label="Data Inicial"
                        variant="outlined"
                        color={
                          themeMode.mode === 'light' ? 'primary' : 'secondary'
                        }
                        value={form.startDate}
                        type="date"
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleChange(e, 'startDate')}
                        sx={{
                          fontFamily: 'Inter'
                        }}
                        InputLabelProps={{ shrink: true }}
                      />

                      <TextField
                        id="outlined-basic"
                        label="Data Final"
                        variant="outlined"
                        color={
                          themeMode.mode === 'light' ? 'primary' : 'secondary'
                        }
                        value={form.endDate}
                        type="date"
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleChange(e, 'endDate')}
                        sx={{
                          fontFamily: 'Inter'
                        }}
                        InputLabelProps={{ shrink: true }}
                      />

                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={form.sharedBrand}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ): void => handleChange(e, 'sharedBrand')}
                            />
                          }
                          label="Manual da Marca"
                        />
                      </FormGroup>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          gap: '1rem'
                        }}
                      >
                        <LoadingButton
                          loading={loading}
                          onClick={onSubmit}
                          variant="contained"
                          style={{
                            backgroundColor:
                              themeMode.mode === 'light'
                                ? `${theme.palette.primary.main}`
                                : `${theme.palette.secondary.main}`,
                            color: loading
                              ? themeMode.mode === 'light'
                                ? `${theme.palette.primary.main}`
                                : `${theme.palette.secondary.main}`
                              : themeMode.mode === 'light'
                              ? `${theme.palette.text.white}`
                              : `${theme.palette.text.black}`
                          }}
                          sx={{
                            textTransform: 'none!important',
                            fontFamily: 'Inter'
                          }}
                        >
                          Salvar
                        </LoadingButton>
                      </div>
                    </Box>
                  </Box>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
          <Grid item xs={12}>
            <BusinessUsers
              business={form}
              onlyView={false}
              onRefresh={onRefresh}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessUpdate;
