/* eslint-disable no-undef */
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { BasicDispatchParam } from '../../models/dispatchTypes';
import DocumentsRequests from '../repositories/documents';

import {
  DOCUMENTS_DETAIL,
  DOCUMENTS_CLEAR_DETAIL,
  DOCUMENTS_LIST,
  TEXT_EDIT,
  DOCUMENTS_CREATE,
  DOCUMENTS_DELETE,
  TEXT_CREATE
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import RequestErrorHelper from '../../utils/helper/RequestErrorHelper';
import notify from '../../utils/notify';
import analytics from '../../utils/function/analytics';
import mapping from '../../utils/function/mapping';

export const createNewDocument =
  (cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.Document[]>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('text', 'create');

      await mapping.track('Create Text');

      const payload = await DocumentsRequests.getNewDocument();

      // const payload: models.Document[] =
      //   await DocumentsRequests.getAllDocuments();

      const editorPayload: any = {
        textId: payload._id,
        title: payload.title,
        text: payload.fullText,
        rawEditorState: payload.contentState
      };

      dispatch({
        payload,
        type: DOCUMENTS_CREATE
      });

      dispatch({
        payload: editorPayload,
        type: TEXT_CREATE
      });

      if (cb && cb.success) {
        cb.success(payload._id);
      }
    } catch (err) {
      if (err instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        notify.error('Você já atingiu o limite de textos salvos');
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getAllDocument =
  (cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.Document[]>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('text', 'get-all-texts');

      await mapping.track('Get All Texts');

      const payload: models.Document[] =
        await DocumentsRequests.getAllDocuments();

      dispatch({
        payload,
        type: DOCUMENTS_LIST
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);

        if (cb && cb.error) {
          cb.error();
        }
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getDocumentById =
  (idDocument: string, navigate: any, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('text', 'open');

      await mapping.track('Open Text in Editor');

      const documentPayload: models.DocumentDetail =
        await DocumentsRequests.getDocumentById(idDocument);

      const editorPayload: models.Editor = {
        textId: documentPayload._id,
        title: documentPayload.title,
        text: documentPayload.fullText,
        rawEditorState: documentPayload.contentState
      };

      dispatch({
        payload: documentPayload,
        type: DOCUMENTS_DETAIL
      });

      dispatch({
        payload: editorPayload,
        type: TEXT_EDIT
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);

        if (
          e.response &&
          e?.response?.status >= 400 &&
          e?.response?.status < 500
        ) {
          if (e?.response?.data.message === 'Texto não encontrado') {
            navigate('/home');
            // navigate('/documents');
          }
        }
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const deleteDocumentById =
  (idDocument: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.Document[]>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('text', 'delete');

      await mapping.track('Delete Text');

      await DocumentsRequests.deleteDocument(idDocument);

      // const payload: models.Document[] =
      //   await DocumentsRequests.getAllDocuments();

      const payload: any = {
        _id: idDocument
      };

      dispatch({
        payload,
        type: DOCUMENTS_DELETE
      });

      if (cb && cb.success) {
        cb.success();
      }

      notify.success('Documento excluído com sucesso!');
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        notify.error(
          'Ocorreu algum erro ao excluir seu documento, tente novamente.'
        );
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const clearTemplatesDetails =
  () => (dispatch: Dispatch<BasicDispatchParam<undefined>>) => {
    dispatch({
      payload: undefined,
      type: DOCUMENTS_CLEAR_DETAIL
    });
  };
