import {
  AccountCircleRounded,
  AppsRounded,
  ArticleRounded,
  BookmarkRounded,
  ContentPasteOffRounded,
  DashboardRounded,
  HomeRounded,
  InfoRounded,
  NewspaperRounded,
  QuestionAnswerRounded
} from '@mui/icons-material';
import ProfilePage from '../../../layouts/App/pages/settings/Profile';
import TemplatesPage from '../../../layouts/App/pages/templates';
import ChatPage from '../../../layouts/App/pages/chat';
import DocumentsPage from '../../../layouts/App/pages/documents';
import SettingsPageLayout from '../../../layouts/App/pages/settings';
import { RouteType } from './interface/config';
import SignIn from '../../../layouts/Auth/pages/SignIn';
import SignUp from '../../../layouts/Auth/pages/SignUp';
import ForgotPassword from '../../../layouts/Auth/pages/ForgotPassword';
import PasswordRecovery from '../../../layouts/Auth/pages/PasswordRecovery';
import CreditsBilling from '../../../layouts/App/pages/settings/CreditsBilling';
import EditProfile from '../../../layouts/App/pages/settings/EditProfile';
import BusinessPage from '../../../layouts/App/pages/settings/Business';
import BackofficePage from '../../../layouts/App/pages/settings/Backoffice/Business/List';
import HomePage from '../../../layouts/App/pages/home/';
import EditTextPage from '../../../layouts/App/pages/editText';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import HelpIcon from '@mui/icons-material/Help';
import DeselectIcon from '@mui/icons-material/Deselect';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import HubIcon from '@mui/icons-material/Hub';
// import PaymentContainer from '../../../layouts/App/pages/payment';
import Plans from '../../../layouts/App/pages/plans';
import PaymentCheckout from '../../../layouts/App/pages/payment/checkout';
import ListBrand from '../../../layouts/App/pages/brands/List';
import CreateBrand from '../../../layouts/App/pages/brands/Create';
import UpdateBrand from '../../../layouts/App/pages/brands/Update';
import ListProjects from '../../../layouts/App/pages/projects/List';

import BackofficeUpdateBusiness from '../../../layouts/App/pages/settings/Backoffice/Business/Update';

const routes: RouteType[] = [
  // {
  //   sidebar: 0,
  //   index: true,
  //   element: <SignIn />,
  //   state: 'sign-in',
  //   protected: false
  // },
  {
    index: true,
    element: <HomePage />,
    state: 'home',
    protected: true
  },
  // {
  //   path: '/premium',
  //   index: true,
  //   element: <PaymentContainer />,
  //   state: 'payment',
  //   protected: true
  // },
  {
    path: '/plans',
    index: true,
    element: <Plans />,
    state: 'payment',
    protected: true
  },
  {
    path: '/payment/checkout/*',
    index: true,
    element: <PaymentCheckout />,
    state: 'payment-checkout',
    protected: true
  },
  {
    path: '/sign-in/*',
    element: <SignIn />,
    state: 'sign-in',
    protected: false,
    child: [
      {
        path: ':code',
        element: <SignIn />,
        state: 'sign-in',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    sidebar: 0,
    path: '/sign-up',
    element: <SignUp />,
    state: 'sign-up',
    protected: false
  },
  {
    sidebar: 0,
    path: '/forgot-password',
    element: <ForgotPassword />,
    state: 'forgot-password',
    protected: false
  },
  {
    sidebar: 0,
    path: '/password-recovery/*',
    element: <PasswordRecovery />,
    state: 'password-recovery',
    protected: false,
    child: [
      {
        sidebar: 0,
        path: ':code',
        element: <PasswordRecovery />,
        state: 'password-recovery',
        protected: false
      }
    ]
  },
  {
    sidebar: 1,
    path: '/home',
    element: <HomePage />,
    state: 'home',
    sidebarProps: {
      displayText: 'Página Inicial',
      icon: <HomeRounded />
    },
    protected: true
  },
  {
    sidebar: 1,
    path: '/templates',
    element: <TemplatesPage />,
    state: 'templates',
    sidebarHidden: true,
    sidebarProps: {
      displayText: 'Templates',
      icon: <DashboardRounded />
    },
    protected: true
    // child: [
    //   {
    //     path: ':type',
    //     element: <TemplatesPage />,
    //     state: 'templates',
    //     protected: false
    //   }
    // ]
  },
  {
    sidebar: 1,
    hidden: true,
    path: '/templates/:type',
    element: <TemplatesPage />,
    state: 'templates',
    sidebarHidden: true,
    sidebarProps: {
      displayText: 'Templates',
      icon: <AppsRounded />
    },
    protected: true
  },
  {
    sidebar: 1,
    path: '/chat',
    element: <ChatPage />,
    state: 'chat',
    sidebarProps: {
      displayText: 'Chat',
      icon: <QuestionAnswerRounded />
    },
    protected: true
  },
  // {
  //   sidebar: 1,
  //   path: '/documents',
  //   element: <DocumentsPage />,
  //   state: 'documents',
  //   sidebarProps: {
  //     displayText: 'Documentos',
  //     icon: <ArticleRounded />
  //   },
  //   protected: true
  // },
  {
    sidebar: 1,
    path: '/edit-text/:id',
    element: <EditTextPage />,
    state: 'editText',
    sidebarProps: {
      displayText: 'Edição de texto'
    },
    protected: true,
    hidden: true
  },
  {
    sidebar: 1,
    path: '/projects',
    element: <ListProjects />,
    state: 'projects',
    hidden: true,
    protected: true
  },
  {
    sidebar: 2,
    path: '/brands',
    element: <ListBrand />,
    state: 'brands',
    sidebarProps: {
      displayText: 'Central da Marca',
      icon: <HubIcon />
    },
    hidden: true,
    protected: true
  },
  {
    sidebar: 1,
    path: '/brands/create',
    element: <CreateBrand />,
    state: 'brands-create',
    sidebarProps: {
      displayText: 'Criar Marca'
    },
    protected: true,
    hidden: true
  },
  {
    sidebar: 1,
    path: '/brands/update/:id',
    element: <UpdateBrand />,
    state: 'brands-update',
    sidebarProps: {
      displayText: 'Editar Marca'
    },
    protected: true,
    hidden: true
  },
  {
    sidebar: 1,
    path: '/backoffice/business/:id',
    element: <BackofficeUpdateBusiness />,
    state: 'backoffice-business-update',
    sidebarProps: {
      displayText: 'Editar Negócio'
    },
    protected: true,
    hidden: true
  },
  {
    sidebar: 1,
    path: '/backoffice',
    element: <BackofficePage />,
    state: 'backoffice',
    sidebarProps: {
      displayText: 'Backoffice'
    },
    protected: true,
    hidden: true
  },
  {
    sidebar: 2,
    onClick: true,
    state: 'disabledRules',
    sidebarProps: {
      displayText: 'Regras Desativadas',
      icon: <ContentPasteOffRounded />
    },
    protected: true
  },
  {
    sidebar: 2,
    onClick: true,
    state: 'dictionary',
    sidebarProps: {
      displayText: 'Dicionário',
      icon: <BookmarkRounded />
    },
    protected: true
  },
  {
    hidden: true,
    sidebar: 1,
    path: '/account',
    element: <SettingsPageLayout />,
    state: 'account',
    sidebarProps: {
      displayText: 'Conta',
      icon: <AccountCircleRounded />
    },
    protected: true,
    child: [
      {
        sidebar: 1,
        path: '/account/profile',
        element: <ProfilePage />,
        state: 'account.profile',
        sidebarProps: {
          displayText: 'Perfil'
          /* icon: <PersonRounded />, */
        }
      },
      {
        sidebar: 1,
        path: '/account/edit-account',
        element: <EditProfile />,
        state: 'account.edit-account',
        sidebarProps: {
          displayText: 'Editar Conta'
          /* icon: <DarkModeRounded />, */
        }
      },
      {
        sidebar: 1,
        path: '/account/credits-billings',
        element: <CreditsBilling />,
        state: 'account.credits-billings',
        sidebarProps: {
          displayText: 'Créditos e Cobrança'
        }
      },
      {
        sidebar: 2,
        path: '/account/business',
        element: <BusinessPage />,
        state: 'account.business',
        sidebarProps: {
          displayText: 'Meu Negócio'
          /* icon: <PersonRounded />, */
        }
      }
    ]
  },
  // {
  //   sidebar: 2,
  //   onClick: true,
  //   state: 'news',
  //   sidebarProps: {
  //     displayText: 'Novidades',
  //     icon: <NewspaperRounded />
  //   },
  //   protected: true
  // },
  // {
  //   sidebar: 2,
  //   onClick: true,
  //   state: 'terms',
  //   sidebarProps: {
  //     displayText: 'Termos de Uso',
  //     icon: <HelpIcon />
  //   },
  //   protected: true
  // },
  // {
  //   sidebar: 2,
  //   onClick: true,
  //   state: 'privacyPolicy',
  //   sidebarProps: {
  //     displayText: 'Política de Privacidade',
  //     icon: <PrivacyTipIcon />
  //   },
  //   protected: true
  // },
  // {
  //   sidebar: 2,
  //   path: '/help',
  //   element: <SettingsPageLayout />,
  //   state: 'help',
  //   sidebarProps: {
  //     displayText: 'Ajuda',
  //     icon: <InfoRounded />
  //   },
  //   protected: true
  // },
  {
    sidebar: 2,
    onClick: true,
    state: 'help',
    sidebarProps: {
      displayText: 'Enviar Feedback',
      icon: <InfoRounded />
    },
    protected: true
  }
];

export default routes;
