import React, { useState } from 'react';
import { SignInFormInterface } from './interface';
import useStyles from './style';
import HeadForm from '../../../../components/HeadForm';
import TitleForm from '../../../../components/TitleForm';
import GoogleButton from '../../../../components/GoogleButton';
import ButtonSubmit from '../../../../components/ButtonSubmit';
import LinkContainer from '../../../../components/LinkContainer';
import CheckboxKeepConnected from '../CheckboxKeepConnected';
import { Link } from 'react-router-dom';
import Input from '../../../../components/Input';
import ReCAPTCHA from 'react-google-recaptcha';

const SignInForm = ({
  email,
  setEmail,
  password,
  setPassword,
  captcha,
  setCaptcha,
  connected,
  loading,
  handleChange,
  onSubmit
}: SignInFormInterface) => {
  const classes: any = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <HeadForm
          title="Primeiro acesso?"
          textButton="Crie uma conta"
          routeButton="/sign-up"
        />

        <TitleForm title="Acesse sua conta" />

        <GoogleButton>Fazer Login com Google</GoogleButton>

        <form action="" onSubmit={onSubmit} className={classes.form}>
          <Input
            id="email"
            label="E-mail"
            placeholder="Insira seu e-mail"
            type="text"
            value={email}
            setValue={setEmail}
          />

          <Input
            id="senha"
            label="Senha"
            placeholder="Insira sua senha"
            type="password"
            value={password}
            setValue={setPassword}
          />

          <ReCAPTCHA
            sitekey="6Lck3ToaAAAAAA-EZIPMqAA3nQ-PtyKhoa0K3oU5"
            onChange={() => setCaptcha(true)}
            onErrored={() => setCaptcha(false)}
            onExpired={() => setCaptcha(false)}
            className={classes.captcha}
          />

          <LinkContainer>
            <CheckboxKeepConnected
              connected={connected}
              handleChange={handleChange}
            />

            <Link to="/forgot-password" className={classes.link}>
              Esqueceu sua senha?
            </Link>
          </LinkContainer>

          <ButtonSubmit disabled={loading || !captcha} loading={loading}>
            Entrar
          </ButtonSubmit>
        </form>
      </div>
    </div>
  );
};

export default SignInForm;
