import { BusinessUsersInterface } from './interface';
import {
  Grid,
  Box,
  TextField,
  Divider,
  useTheme,
  Button,
  Chip
} from '@mui/material';
import useStyles from './style';
import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../../../../../../../../utils/context/ThemeContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { businessDeleteUser } from '../../../../../../../../../stories/actions/business';
import { useAppDispatch } from '../../../../../../../../../hooks/useAppDispatch';
import ModalBusinessAddUser from '../ModalBusinessAddUser';
import { backofficeBusinessDeleteUser } from '../../../../../../../../../stories/actions/backoffice';

const BusinessUsers = ({
  business,
  onlyView,
  onRefresh
}: BusinessUsersInterface) => {
  const classes: any = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const dispatch = useAppDispatch();

  const onDelete = async (_id: string) => {
    const cb = {
      success: (business: any) => {
        onRefresh(business);
      },
      error: () => {}
    };

    dispatch(backofficeBusinessDeleteUser(_id, cb));
  };

  const formatDate = (isoString: any) => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString('pt-BR');
    return formattedDate;
  };

  return (
    <Box className={classes.root}>
      <ModalBusinessAddUser
        open={modalOpen}
        toggle={toggleModal}
        business={business}
        onRefresh={onRefresh}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={12}>
            <div className={classes.contentContainer}>
              <div className={classes.formContainer}>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  flexDirection={'row'}
                  width={'100%'}
                >
                  <p
                    className={classes.title}
                    style={{
                      minWidth: '50%'
                    }}
                  >
                    Usuários{' '}
                    {business?.licenses && (
                      <Chip
                        label={
                          business?.users?.length +
                          ' de ' +
                          business?.licenses +
                          ' licenças utilizadas'
                        }
                        color={
                          themeMode.mode === 'light' ? 'primary' : 'secondary'
                        }
                        sx={{
                          height: '32px!important',
                          fontSize: '0.8rem!important',
                          fontWeight: '700!important',
                          marginLeft: '5px',
                          background:
                            themeMode.mode === 'light'
                              ? 'rgba(70, 189, 159, 1)'
                              : 'rgba(70, 189, 159, 1)'
                        }}
                        size="small"
                      />
                    )}
                  </p>

                  {!onlyView && (
                    <Grid item xs={12} className={classes.submitContainer}>
                      <LoadingButton
                        style={{
                          fontFamily: 'Inter',
                          marginLeft: '0px',
                          backgroundColor:
                            themeMode.mode === 'light'
                              ? `${theme.palette.primary.main}`
                              : `${theme.palette.secondary.main}`,
                          color:
                            themeMode.mode === 'light'
                              ? `${theme.palette.text.white}`
                              : `${theme.palette.text.black}`
                        }}
                        sx={{
                          textTransform: 'none!important'
                        }}
                        variant="contained"
                        onClick={toggleModal}
                      >
                        Adicionar
                      </LoadingButton>
                    </Grid>
                  )}
                </Box>

                {business?.users?.length === 0 && (
                  <p
                    className={classes.description}
                    style={{
                      textAlign: 'center',
                      marginTop: '20px',
                      marginBottom: '20px'
                    }}
                  >
                    Nenhum usuário adicionado até o momento...
                  </p>
                )}
              </div>

              {business?.users?.map((item: any, index: number) => (
                <div
                  style={{
                    paddingLeft: '25px',
                    paddingRight: '25px',
                    paddingTop: '10px',
                    paddingBottom: '10px'
                  }}
                  key={index}
                >
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexDirection={'row'}
                    width={'100%'}
                  >
                    <div>
                      <p className={classes.description}>
                        <b>Nome: </b> {item.name}
                      </p>

                      <p className={classes.description}>
                        <b>E-mail: </b> {item.email}
                      </p>

                      {item.lastAccess && (
                        <p className={classes.description}>
                          <b>Último Acesso: </b> {formatDate(item.lastAccess)}
                        </p>
                      )}
                    </div>

                    <div>
                      {item.main && (
                        <Chip
                          label="Proprietário"
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          // size="small"
                          style={{
                            background:
                              themeMode.mode === 'light'
                                ? 'rgb(124 36 250 / 80%)'
                                : '#8CFFE1'
                          }}
                        />
                      )}

                      {!item?.main && item.status === 'active' && (
                        <Chip
                          label="Ativo"
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          style={{
                            background:
                              themeMode.mode === 'light'
                                ? 'rgba(70, 189, 159, 1)'
                                : 'rgba(70, 189, 159, 1)'
                          }}
                        />
                      )}

                      {!item?.main && item.status === 'pending' && (
                        <Chip
                          label="Pendente"
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          style={{
                            background:
                              themeMode.mode === 'light'
                                ? 'rgba(255, 191, 73, 1)'
                                : 'rgba(255, 191, 73, 1)'
                          }}
                        />
                      )}

                      {!item.main && !onlyView && (
                        <Button
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                          size={'small'}
                          variant="outlined"
                          className={classes.enBtn}
                          onClick={() => onDelete(item._id)}
                          sx={{
                            textTransform: 'none!important',
                            fontFamily: 'Inter',
                            marginLeft: '10px'
                          }}
                        >
                          Excluir
                        </Button>
                      )}
                    </div>
                  </Box>
                  {index != business?.users?.length && (
                    <Divider className={classes.dividerTable} />
                  )}
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessUsers;
