/* eslint-disable no-undef */
import withAuth from '../../utils/axios/withAuth';
import {
  disabledRulesFormatted,
  disableRule,
  updateProjectActive
} from '../actions/user';

const UserApi = {
  getUserById: async (id: string) => {
    const { data } = await withAuth().get(`/users/${id}`);

    return data;
  },
  getUserStats: async (id: string) => {
    const { data } = await withAuth().get(`/users/stats/${id}`);

    return data;
  },
  updateUser: async (_id: string, user: models.User) => {
    const { data } = await withAuth().put(`/users/profile`, {
      name: user.name,
      email: user.email,
      phone: user.phone
    });

    return data;
  },
  updateTheme: async (theme: string) => {
    const { data } = await withAuth().put(`/users/theme`, {
      theme: theme
    });

    return data;
  },
  updateChatHistoric: async (chatHistoric: boolean) => {
    const { data } = await withAuth().put(`/users/chat-historic`, {
      chatHistoric: chatHistoric
    });

    return data;
  },
  updateAcceptedTerms: async (acceptedTerms: boolean) => {
    const { data } = await withAuth().put(`/users/accept-terms`, {
      acceptedTerms: acceptedTerms
    });

    return data;
  },
  updateProjectActive: async (_id: string) => {
    const { data } = await withAuth().put(`/users/project-active`, {
      projectId: _id
    });

    return data;
  },
  updateBrandActive: async (_id: string) => {
    const { data } = await withAuth().put(`/users/brand-active`, {
      brandId: _id
    });

    return data;
  },
  sendSuggestion: async (subject: string, description: string) => {
    const { data } = await withAuth().post('/users/suggestion', {
      subject,
      description
    });

    return data;
  },
  addWordFromDictionary: async (word: string) => {
    const { data } = await withAuth().post('/users/add-dictionary', {
      word
    });

    return data;
  },
  deleteWordFromDictionary: async (word: string) => {
    const { data } = await withAuth().delete(
      `/users/delete-dictionary/${word}`
    );

    return data;
  },
  enableRule: async (id: string) => {
    const { data } = await withAuth().post('/users/enable-rule', {
      id
    });

    return data;
  },
  disableRule: async (id: string) => {
    const { data } = await withAuth().post('/users/disable-rule', {
      id
    });

    return data;
  },
  disabledRulesFormatted: async (rules: any[]) => {
    const { data } = await withAuth().post('/users/disabled-rules/formatted', {
      rules
    });

    return data;
  },
  getSynonyms: async (word: string) => {
    const { data } = await withAuth().get(`/generator/synonyms/${word}`);

    return data;
  }
};

export default UserApi;
