import React, { useState } from 'react';
import { SignUpFormInterface } from './interface';
import useStyles from './style';
import HeadForm from '../../../../components/HeadForm';
import TitleForm from '../../../../components/TitleForm';
import GoogleButton from '../../../../components/GoogleButton';
import ButtonSubmit from '../../../../components/ButtonSubmit';
import LinkContainer from '../../../../components/LinkContainer';
import Input from '../../../../components/Input';
import CheckboxAcceptTerms from '../CheckboxAcceptTerms';
import ReCAPTCHA from 'react-google-recaptcha';
import TooltipContainer from '../../../../../App/components/TooltipContainer';

const SignUpForm = ({
  name,
  setName,
  email,
  setEmail,
  password,
  setPassword,
  accept,
  loading,
  handleChange,
  onSubmit,
  captcha,
  setCaptcha
}: SignUpFormInterface) => {
  const classes: any = useStyles();

  const tooltip = () => {
    if (name === '') {
      return 'O campo de nome é obrigatório.';
    }

    if (email === '') {
      return 'O campo de e-mail é obrigatório.';
    }

    if (password === '') {
      return 'O campo de senha é obrigatório.';
    }

    if (!captcha) {
      return 'Por favor, realize o captcha.';
    }

    if (!accept) {
      return 'Por favor, aceite os termos.';
    }

    return '';
  };

  return (
    <div className={classes.root}>
      <div>
        <HeadForm
          title="Já possui uma conta?"
          textButton="Entrar"
          routeButton="/sign-in"
        />

        <TitleForm title="Crie sua conta" />

        <GoogleButton>Cadastre-se com Google</GoogleButton>

        <form action="" onSubmit={onSubmit} className={classes.form}>
          <Input
            id="email"
            label="Como podemos te chamar?"
            placeholder="Insira seu nome"
            type="text"
            value={name}
            setValue={setName}
          />
          <Input
            id="email"
            label="E-mail"
            placeholder="Insira seu e-mail"
            type="text"
            value={email}
            setValue={setEmail}
          />
          <Input
            id="senha"
            label="Senha"
            placeholder="Insira sua senha"
            type="password"
            value={password}
            setValue={setPassword}
          />
          <ReCAPTCHA
            sitekey="6Lck3ToaAAAAAA-EZIPMqAA3nQ-PtyKhoa0K3oU5"
            onChange={() => setCaptcha(true)}
            onErrored={() => setCaptcha(false)}
            onExpired={() => setCaptcha(false)}
            className={classes.captcha}
          />
          <LinkContainer>
            <CheckboxAcceptTerms accept={accept} handleChange={handleChange} />
          </LinkContainer>

          <TooltipContainer title={tooltip()} placement="bottom">
            <div>
              <ButtonSubmit
                disabled={loading || !accept || !captcha}
                loading={loading}
              >
                Criar conta
              </ButtonSubmit>
            </div>
          </TooltipContainer>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
