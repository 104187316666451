import React, { useContext } from 'react';
import useStyles from './style';
import { Grid, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import Businesses from './components/Businesses';
import { isBetaUser } from '../../../../../../../utils/function/isBetaUser';
import { useReduxState } from '../../../../../../../hooks/useReduxState';

const BusinessList: React.FC = () => {
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const classes = useStyles();

  const { user } = useReduxState();

  const isCollaborators = isBetaUser(user, 'clarice-collaborators');

  const onlyView = !isBetaUser(user, 'clarice-collaborators');

  if (!isCollaborators) {
    return (
      <Box className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={12}>
              <div className={classes.contentContainer}>
                <div className={classes.formContainer}>
                  <p className={classes.title}>Backoffice</p>
                  <p
                    className={classes.description}
                    style={{ textAlign: 'left' }}
                  >
                    Você não tem permissão para acessar essa página...
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return <Businesses onlyView={onlyView} />;
};

export default BusinessList;
