import React, { useContext, useEffect, useState } from 'react';
import useStyles from './style';
import { TextField, Grid, useTheme, Divider } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useReduxState } from '../../../../../hooks/useReduxState';
import { updateUser } from '../../../../../stories/actions/user';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import InputMask from 'react-input-mask';
import {
  createBusiness,
  updateBusiness
} from '../../../../../stories/actions/business';
import BusinessUsers from './components/BusinessUsers';

const Business: React.FC = () => {
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { user, business } = useReduxState();

  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-undef
  const [form, setForm] = useState<any>({
    // _id: user.detail?._id as string,
    name: ''
  });

  const isBusiness =
    user.isSubscriber &&
    ['Assinatura Mensal | Business', 'Assinatura Anual | Business'].includes(
      user?.options?.plan?.type
    );

  useEffect(() => {
    if (business && business.detail) {
      setForm({
        _id: business.detail?._id as string,
        name: business.detail?.name as string
      });
    }
  }, [business]);

  const onlyView = user.isSubscriber
    ? business.detail?.user?._id
      ? user?.detail?._id !== business.detail?.user?._id
      : false
    : true;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    if (onlyView) {
      return;
    }

    setForm({ ...form, [type]: e.target.value });
  };

  const onCreate = async () => {
    setLoading(true);

    const cb = {
      success: () => {
        setLoading(false);
      },
      error: () => {
        setLoading(false);
      }
    };

    dispatch(createBusiness(form, cb));
  };

  const onUpdate = async () => {
    if (onlyView) {
      return;
    }

    setLoading(true);

    const cb = {
      success: () => {
        setLoading(false);
      },
      error: () => {
        setLoading(false);
      }
    };

    dispatch(updateBusiness(form, cb));
  };

  if (!isBusiness) {
    return (
      <Box className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={12}>
              <div className={classes.contentContainer}>
                <div className={classes.formContainer}>
                  <p className={classes.title}>Meu Negócio</p>
                  <p
                    className={classes.description}
                    style={{ textAlign: 'left' }}
                  >
                    Você não tem permissão para acessar essa página...
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (form?._id) {
    return (
      <>
        <Box className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12}>
                <div className={classes.contentContainer}>
                  <div className={classes.formContainer}>
                    <p className={classes.title}>Meu Negócio</p>
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { width: '100%' }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        className={classes.input}
                        id="outlined-basic"
                        label="Nome"
                        variant="outlined"
                        placeholder="Digite seu nome..."
                        multiline
                        value={form.name}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleChange(e, 'name')}
                        color={
                          themeMode.mode === 'light' ? 'primary' : 'secondary'
                        }
                        sx={{
                          fontFamily: 'Inter'
                        }}
                        disabled={onlyView}
                      />
                    </Box>
                    {!onlyView && (
                      <Grid item xs={12} className={classes.submitContainer}>
                        <LoadingButton
                          style={{
                            fontFamily: 'Inter',
                            marginLeft: '0px',
                            backgroundColor:
                              themeMode.mode === 'light'
                                ? `${theme.palette.primary.main}`
                                : `${theme.palette.secondary.main}`,
                            color: loading
                              ? themeMode.mode === 'light'
                                ? `${theme.palette.primary.main}`
                                : `${theme.palette.secondary.main}`
                              : themeMode.mode === 'light'
                              ? `${theme.palette.text.white}`
                              : `${theme.palette.text.black}`
                          }}
                          sx={{
                            textTransform: 'none!important'
                          }}
                          loading={loading}
                          variant="contained"
                          onClick={onUpdate}
                        >
                          Salvar
                        </LoadingButton>
                      </Grid>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <BusinessUsers onlyView={onlyView} />
      </>
    );
  }

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={12}>
            <div className={classes.contentContainer}>
              <div className={classes.formContainer}>
                <p className={classes.title}>Meu Negócio</p>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { width: '100%' }
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    className={classes.input}
                    id="outlined-basic"
                    label="Nome"
                    variant="outlined"
                    placeholder="Digite seu nome..."
                    multiline
                    value={form.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleChange(e, 'name')
                    }
                    color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                    sx={{
                      fontFamily: 'Inter'
                    }}
                  />
                </Box>
                <Grid item xs={12} className={classes.submitContainer}>
                  <LoadingButton
                    style={{
                      fontFamily: 'Inter',
                      marginLeft: '0px',
                      backgroundColor:
                        themeMode.mode === 'light'
                          ? `${theme.palette.primary.main}`
                          : `${theme.palette.secondary.main}`,
                      color: loading
                        ? themeMode.mode === 'light'
                          ? `${theme.palette.primary.main}`
                          : `${theme.palette.secondary.main}`
                        : themeMode.mode === 'light'
                        ? `${theme.palette.text.white}`
                        : `${theme.palette.text.black}`
                    }}
                    sx={{
                      textTransform: 'none!important'
                    }}
                    loading={loading}
                    variant="contained"
                    onClick={onCreate}
                  >
                    Cadastrar
                  </LoadingButton>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Business;
