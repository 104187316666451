import React, { useEffect, useState } from 'react';
import useStyles from './style';
import { Box, useMediaQuery } from '@mui/material';
import ScoreItem from './ScoreItem';
import CustomModal from './CustomModal';
import { useReduxState } from '../../../../../../../../hooks/useReduxState';
import descriptionReadability from './function/descriptionReadability';
import formatReadability from './function/formatReadability';
import { getProcessReadability } from '../../../../../../../../stories/actions/editor';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import { isBetaUser } from '../../../../../../../../utils/function/isBetaUser';

const ModalScore = ({ open, toggle }) => {
  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { editor, user, documents } = useReduxState();

  const text = documents.detail?.fullText;

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const score = {
    characters: text?.replace(/\n+/g, '').length,
    words: text?.split(/\s+/).filter(el => el.length > 0 && el !== '—').length,
    paragraphs: text?.split(/\n+/).filter(el => el.length > 0).length,
    sentences: text?.split(/[!?.]\s/).filter(el => el.length > 0).length
  };

  const readingTime = () => {
    const minutes: any = ((score.words as number) / 230).toFixed(0);
    const seconds = Math.ceil(
      Math.abs(minutes - (score.words as number) / 230) * 60
    );
    return `${minutes} min ${seconds} seg`;
  };

  const speakingTime = () => {
    const minutes: any = ((score.words as number) / 130).toFixed(0);
    const seconds = Math.ceil(
      Math.abs(((minutes - (score.words as number)) / 130) * 60)
    );
    return `${minutes} min ${seconds} seg`;
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
    } else {
      setLoading(true);
    }
  }, [open]);

  useEffect(() => {
    if (loading) {
      const cb = {
        success: () => {
          setLoading(false);
        },
        error: () => {
          setLoading(false);
        }
      };

      dispatch(getProcessReadability(editor.text, cb));
    }
  }, [loading]);

  const readability = {
    wordLength: formatReadability(editor.process?.readability.avgWordLength)
      .string,
    sentenceLength: formatReadability(
      editor.process?.readability.avgSentenceLength
    ).string,
    // readabilityScore: formatReadability(
    //   editor.process?.readability.score?.fleshKincaid
    // ),
    readabilityScore: formatReadability(
      editor.process?.readability.score?.flesh
    ),
    legibility: {
      score: formatReadability(
        Number(editor.process?.readability?.legibility?.score)
      ),
      grade: editor.process?.readability?.legibility?.grade,
      label: editor.process?.readability?.legibility?.label
    },
    sentiment: editor.process?.sentiment || 'Não Identificado',
    genre: editor.process?.genre || 'Não Identificado',
    style: editor.process?.style || 'Não Identificado',
    summary: editor.process?.summary || 'Não Identificado',
    complexWord: formatReadability(
      Number(editor.process?.readability?.complexWord || 0)
    )
  };

  return (
    <CustomModal
      open={open}
      toggle={() => {
        toggle();
      }}
    >
      <Box
        className={classes.box}
        style={{
          // height: isMobile ? '600px' : '100%',
          maxHeight: isMobile ? '600px' : '100%',
          paddingRight: isMobile ? '0px' : '43px',
          touchAction: 'auto'
        }}
      >
        <ScoreItem
          title="Performance"
          description="A pontuação ao lado representa a qualidade de escrita neste documento. Você pode melhorá-la seguindo as sugestões da Clarice.ai."
          type="performance"
        />

        <ScoreItem
          title="Métricas do Texto"
          type="wordCount"
          characters={score.characters}
          words={score.words}
          paragraphs={score.paragraphs}
          readingTime={readingTime()}
          speakingTime={speakingTime()}
          sentences={score.sentences}
        />

        {!isBetaUser(user, 'score') && (
          <ScoreItem
            title="Legibilidade"
            type="readability"
            loading={loading}
            description={descriptionReadability(
              readability.readabilityScore.number
            )}
            wordLength={readability.wordLength}
            sentenceLength={readability.sentenceLength}
            readabilityScore={readability.readabilityScore.string}
            legibility={readability.legibility}
            sentiment={readability.sentiment}
            genre={readability.genre}
            style={readability.style}
          />
        )}

        {isBetaUser(user, 'score') && (
          <ScoreItem
            title="Legibilidade"
            type="legibility"
            loading={loading}
            description={descriptionReadability(
              readability.readabilityScore.number
            )}
            wordLength={readability.wordLength}
            sentenceLength={readability.sentenceLength}
            readabilityScore={readability.readabilityScore.string}
            legibility={readability.legibility}
            sentiment={readability.sentiment}
            genre={readability.genre}
            style={readability.style}
            complexWord={readability.complexWord.string}
          />
        )}

        {/*{isBetaUser(user) && (*/}
        {/*  <ScoreItem*/}
        {/*    title="Sumarização"*/}
        {/*    type="summary"*/}
        {/*    summary={readability.summary}*/}
        {/*    loading={loading}*/}
        {/*  />*/}
        {/*)}*/}
      </Box>
    </CustomModal>
  );
};

export default ModalScore;
