import React, { useContext, useEffect, useState } from 'react';
import Root from './components/Root';
import Title from './components/Title';
import Logo from './components/Logo';
import Info from './components/Info';
import Action from './components/Action';
import { GlobalContext } from '../../../../../utils/context/GlobalContext';
import { useReduxState } from '../../../../../hooks/useReduxState';
import analytics from '../../../../../utils/function/analytics';
import mapping from '../../../../../utils/function/mapping';
import { useNavigate } from 'react-router-dom';
import { openPortal } from '../../../../../stories/actions/payment';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';

type ModalNewsProps = {};

const ModalConversion = ({}: ModalNewsProps) => {
  const navigate = useNavigate();

  const { credits, user } = useReduxState();

  const ctx = useContext(GlobalContext);

  const dispatch = useAppDispatch();

  const [trigger, setTrigger] = useState(0);

  const [isLoadingPortal, setIsLoadingPortal] = useState(false);

  const onOpenPortal = (cb: any) => {
    dispatch(openPortal(cb));
  };

  const handleOpenPortal = () => {
    setIsLoadingPortal(true);
    onOpenPortal(() => setIsLoadingPortal(false));
  };

  const closeModal = () => {
    if (trigger === 50) {
      localStorage.setItem('modal-conversion-trigger-50', String('disabled'));

      localStorage.setItem('modal-conversion-trigger-80', String(''));

      localStorage.setItem('modal-conversion-trigger-100', String(''));
    }

    if (trigger === 80) {
      localStorage.setItem('modal-conversion-trigger-80', String('disabled'));

      localStorage.setItem('modal-conversion-trigger-100', String(''));
    }

    if (trigger === 100) {
      localStorage.setItem('modal-conversion-trigger-100', String('disabled'));
    }

    ctx.modal.conversion.toggle();
  };

  const getInfo = (percentage: number, subscriber: any) => {
    if (!subscriber) {
      if (percentage >= 50 && percentage < 80) {
        return {
          title: 'Seus créditos estão acabando!',
          description:
            'Mas não se preocupe! Com poucos cliques, você poderá adquirir um dos nossos planos PRO e renovar seu limite. Lembre-se: quando seus créditos acabarem, não será possível gerar mais palavras com IA.'
        };
      }

      if (percentage >= 80 && percentage < 100) {
        return {
          title: 'Seus créditos estão acabando!',
          description:
            'Mas não se preocupe! Com poucos cliques, você poderá adquirir um dos nossos planos PRO e renovar seu limite. Lembre-se: quando seus créditos acabarem, não será possível gerar mais palavras com IA.'
        };
      }

      if (percentage >= 100) {
        return {
          title: 'Seus créditos acabaram :(',
          description:
            'Mas não se preocupe! Com poucos cliques, você poderá adquirir um dos nossos planos PRO e renovar seu limite.'
        };
      }
    }

    if (subscriber) {
      if (percentage >= 50 && percentage < 80) {
        return {
          title: 'Seus créditos estão acabando!',
          description:
            'Mas não se preocupe! Com poucos cliques, você poderá fazer um upgrade do seu plano e renovar seu limite. Lembre-se: quando seus créditos acabarem, não será possível gerar mais palavras com IA.'
        };
      }

      if (percentage >= 80 && percentage < 100) {
        return {
          title: 'Seus créditos estão acabando!',
          description:
            'Mas não se preocupe! Com poucos cliques, você poderá fazer um upgrade do seu plano e renovar seu limite. Lembre-se: quando seus créditos acabarem, não será possível gerar mais palavras com IA.'
        };
      }

      if (percentage >= 100) {
        return {
          title: 'Seus créditos acabaram :(',
          description:
            'Mas não se preocupe! Com poucos cliques, você poderá fazer um upgrade do seu plano e renovar seu limite.'
        };
      }
    }

    return {
      title: '',
      description: ''
    };
  };

  const getTrigger = (percentage: number, subscriber: any) => {
    if (!subscriber) {
      if (percentage >= 50 && percentage < 80) {
        if (
          localStorage.getItem('modal-conversion-trigger-50') === 'disabled'
        ) {
          return;
        }

        setTrigger(50);

        ctx.modal.conversion.toggle();

        return;
      }

      if (percentage >= 80 && percentage < 100) {
        if (
          localStorage.getItem('modal-conversion-trigger-80') === 'disabled'
        ) {
          return;
        }

        setTrigger(80);

        ctx.modal.conversion.toggle();

        return;
      }

      if (percentage >= 100) {
        if (
          localStorage.getItem('modal-conversion-trigger-100') === 'disabled'
        ) {
          return;
        }

        setTrigger(100);

        if (!ctx.modal.conversion.open) {
          ctx.modal.conversion.toggle();
        }

        return;
      }
    }

    if (subscriber) {
      if (percentage >= 50 && percentage < 80) {
        if (
          localStorage.getItem('modal-conversion-trigger-50') === 'disabled'
        ) {
          return;
        }

        setTrigger(50);

        ctx.modal.conversion.toggle();

        return;
      }

      if (percentage >= 80 && percentage < 100) {
        if (
          localStorage.getItem('modal-conversion-trigger-80') === 'disabled'
        ) {
          return;
        }

        setTrigger(80);

        ctx.modal.conversion.toggle();

        return;
      }

      if (percentage >= 100) {
        if (
          localStorage.getItem('modal-conversion-trigger-100') === 'disabled'
        ) {
          return;
        }

        setTrigger(100);

        if (!ctx.modal.conversion.open) {
          ctx.modal.conversion.toggle();
        }

        return;
      }
    }
  };

  const getAction = (user: any) => {
    if (user.isSubscriber) {
      return {
        label: 'Faça um upgrade',
        onClick: (event: React.SyntheticEvent) => {
          event.preventDefault();

          analytics.logEvent('CTA', 'modal-conversion-user-pro-upgrade-plan');

          mapping.track('CTA | modal-conversion-user-pro-upgrade-plan');

          closeModal();

          handleOpenPortal();
        }
      };
    }

    if (user?.subscription?.current_period_end)
      return {
        label: 'Torne-se PRO',
        onClick: (event: React.SyntheticEvent) => {
          event.preventDefault();

          analytics.logEvent('CTA', 'modal-conversion-user-free-upgrade-plan');

          mapping.track('CTA | modal-conversion-user-free-upgrade-plan');

          closeModal();

          navigate('/plans');
        }
      };

    return {
      label: '7 Dias Grátis',
      onClick: (event: React.SyntheticEvent) => {
        event.preventDefault();

        analytics.logEvent('CTA', 'modal-conversion-user-free-upgrade-plan');

        mapping.track('CTA | modal-conversion-user-free-upgrade-plan');

        closeModal();

        navigate('/plans');
      }
    };
  };

  const total = {
    percentage: credits?.bonus
      ? ((credits.bonus.words.used + (credits?.plan?.words?.used || 0)) * 100) /
        (credits.bonus.words.total + (credits?.plan?.words?.total || 0))
      : 0
  };

  const action: any = getAction(user);

  useEffect(() => {
    if (
      [
        'Assinatura Mensal | Profissional',
        'Assinatura Anual | Profissional',
        'Assinatura Mensal | Business',
        'Assinatura Anual | Business'
      ].includes(user?.options?.plan?.type)
    ) {
      return;
    } else {
      getTrigger(total.percentage, user.isSubscriber);
    }
  }, [total.percentage, user]);

  const state: any = getInfo(total.percentage, user.isSubscriber);

  return (
    <Root open={ctx.modal.conversion.open} toggle={closeModal}>
      <Logo />

      {state?.title && <Title>{state?.title}</Title>}

      {state?.description && <Info>{state?.description}</Info>}

      <Action label={action.label} onClick={action.onClick} />
    </Root>
  );
};

export default ModalConversion;
