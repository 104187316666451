import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  List,
  ListItem,
  ListItemText,
  useTheme
} from '@mui/material';
import { AddRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import useStyles from './style';
import { useReduxState } from '../../../../../../../../hooks/useReduxState';
import { GlobalContext } from '../../../../../../../../utils/context/GlobalContext';
import { ThemeContext } from '../../../../../../../../utils/context/ThemeContext';
import { isBetaUser } from '../../../../../../../../utils/function/isBetaUser';

type Props = {
  activeBrand: string;
  setActiveBrand: (_id: string) => void;
  modalVisible: boolean;
  setModalVisible: (_id: boolean) => void;
  toggle?: any;
};

const ModalBrands = ({
  activeBrand,
  setActiveBrand,
  modalVisible,
  setModalVisible,
  toggle
}: Props) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const navigate = useNavigate();

  const ctx = useContext(GlobalContext);

  const { brands } = useReduxState();

  const { all } = brands;

  const preventDefault = (event: React.SyntheticEvent) => {
    event.preventDefault();

    setModalVisible(!modalVisible);

    toggle();

    navigate(`/brands`);
  };

  const listRef: any = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setTimeout(() => {
          setModalVisible(false);
        }, 200);
      }
    }

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [listRef]);

  const { user }: any = useReduxState();

  const topCard = () => {
    if (
      [
        'Assinatura Mensal | Profissional',
        'Assinatura Anual | Profissional',
        'Assinatura Mensal | Business',
        'Assinatura Anual | Business'
      ].includes(user?.options?.plan?.type) &&
      isBetaUser(user, 'brand')
    ) {
      return '-217px';
    }

    return '-217px';

    // return '-328px';
  };

  return (
    <>
      <Card
        className={classes.root}
        onClick={() => setModalVisible(!modalVisible)}
        ref={listRef}
        sx={{
          fontFamily: 'Inter',
          backgroundColor: themeMode.mode === 'light' ? `#fff` : '#252525',
          boxShadow: 'none',
          top: topCard() || '-217px'
        }}
      >
        <List className={classes.list} subheader={<li />}>
          <ul key={'no-selected'}>
            <ListItem
              className={
                activeBrand === 'no_selected'
                  ? classes.listItemActive
                  : classes.listItem
              }
              style={{
                cursor: 'pointer',
                color: themeMode.mode === 'light' ? '#303030' : '#fff'
              }}
              key={`item-no-selected`}
              onClick={() => setActiveBrand('no-selected')}
            >
              <p
                style={{
                  fontFamily: 'Inter!important',
                  margin: '0',
                  fontSize: '15px',
                  fontWeight: '500'
                }}
              >
                Não selecionado
              </p>
            </ListItem>
          </ul>

          {all.map((brand: any) => (
            <ul key={brand._id}>
              <ListItem
                className={
                  String(activeBrand) === String(brand._id)
                    ? classes.listItemActive
                    : classes.listItem
                }
                style={{
                  cursor: 'pointer',
                  color: themeMode.mode === 'light' ? '#303030' : '#fff'
                }}
                key={`item-${brand._id}`}
                onClick={() => setActiveBrand(brand._id)}
              >
                <p
                  style={{
                    fontFamily: 'Inter!important',
                    margin: '0',
                    fontSize: '15px',
                    fontWeight: '500'
                  }}
                >
                  {brand.name}
                </p>
              </ListItem>
            </ul>
          ))}
        </List>
        <Box
          className={classes.listItem}
          onClick={preventDefault}
          style={{
            height: '3rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '0 1rem',
            borderTop: `1px solid ${theme.palette.background.darkGrey}`
          }}
        >
          <h2 className={classes.title}>Ver marcas</h2>
          <KeyboardArrowRightRounded
            sx={{
              color: themeMode.mode === 'light' ? '#303030' : '#fff'
            }}
          />
        </Box>
        <Box
          className={classes.listItem}
          onClick={() => {
            navigate(`/brands/create`);

            toggle();
          }}
          style={{
            height: '3rem',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '0.5rem',
            cursor: 'pointer',
            padding: '0 1rem',
            borderTop: `1px solid ${theme.palette.background.darkGrey}`
          }}
        >
          <AddRounded
            sx={{
              color: themeMode.mode === 'light' ? '#303030' : '#fff'
            }}
          />
          <h2 className={classes.title}>Nova Marca</h2>
        </Box>
      </Card>
    </>
  );
};

export default ModalBrands;
