import React, { useContext, useState } from 'react';
import { Box, TextField, Modal, Button, useTheme, Switch } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ModalCustom from '../../../../../../../components/Modal/ModalCustom';
import useStyles from './style';
import notify from '../../../../../../../../../utils/notify';
import { ThemeContext } from '../../../../../../../../../utils/context/ThemeContext';
import { useAppDispatch } from '../../../../../../../../../hooks/useAppDispatch';
import { businessAddUser } from '../../../../../../../../../stories/actions/business';
import {
  backofficeCreateBusiness,
  backofficeGetAllBusiness
} from '../../../../../../../../../stories/actions/backoffice';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

type ModalCreateBusinessProps = {
  open: boolean;
  toggle: () => void;
};

const ModalCreateBusiness = ({ open, toggle }: ModalCreateBusinessProps) => {
  const classes = useStyles();

  const themeMode = useContext(ThemeContext);

  const dispatch = useAppDispatch();

  const theme = useTheme();

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const startDate = new Date();

  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 14);

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const [form, setForm] = useState<any>({
    name: '',
    email: '',
    licenses: 5,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    sharedBrand: true
  });

  console.log('TEST');

  const [loading, setLoading] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    if (type === 'sharedBrand') {
      setForm({ ...form, [type]: e.target.checked });
      return;
    }

    setForm({ ...form, [type]: e.target.value });
  };

  const onClose = async () => {
    setForm({ email: '' });

    setLoading(false);

    toggle();
  };

  const onSubmit = async (e: any) => {
    if (e) e.preventDefault();

    if (form.subject === '' || form.description === '') {
      return notify.error('Preencha os campos!');
    }

    setLoading(true);

    const cb = {
      success: () => {
        setLoading(false);

        setForm({
          name: '',
          email: '',
          licenses: 5,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          sharedBrand: true
        });

        toggle();
      },
      error: () => {
        setLoading(false);
      }
    };

    dispatch(backofficeCreateBusiness(form, cb));
  };

  return (
    <ModalCustom open={open} toggle={onClose} containerClass={classes.root}>
      <form onSubmit={onSubmit} noValidate autoComplete="off">
        <Box
          sx={{
            // width: 400,
            backgroundColor:
              themeMode.mode === 'light'
                ? `${theme.palette.background.paper}`
                : `${theme.palette.background.default}`,
            borderRadius: '15px',
            padding: '0rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
          }}
        >
          <div>
            <h2 id="parent-modal-title">Adicionar Empresa</h2>

            <p id="parent-modal-description">
              Crie uma empresa e vincule a um usuário agora mesmo!
            </p>
          </div>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem'
            }}
          >
            <TextField
              id="outlined-basic"
              label="Nome da empresa"
              variant="outlined"
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              value={form.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                handleChange(e, 'name')
              }
              sx={{
                fontFamily: 'Inter'
              }}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              id="outlined-basic"
              label="E-mail do proprietário"
              variant="outlined"
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              value={form.email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                handleChange(e, 'email')
              }
              sx={{
                fontFamily: 'Inter'
              }}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              id="outlined-basic"
              label="Quantidade de licenças"
              variant="outlined"
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              value={form.licenses}
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                handleChange(e, 'licenses')
              }
              sx={{
                fontFamily: 'Inter'
              }}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              id="outlined-basic"
              label="Data Inicial"
              variant="outlined"
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              value={form.startDate}
              type="date"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                handleChange(e, 'startDate')
              }
              sx={{
                fontFamily: 'Inter'
              }}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              id="outlined-basic"
              label="Data Final"
              variant="outlined"
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              value={form.endDate}
              type="date"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                handleChange(e, 'endDate')
              }
              sx={{
                fontFamily: 'Inter'
              }}
              InputLabelProps={{ shrink: true }}
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={form.sharedBrand}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleChange(e, 'sharedBrand')
                    }
                  />
                }
                label="Manual da Marca"
              />
            </FormGroup>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '1rem'
              }}
            >
              <Button
                onClick={onClose}
                variant="outlined"
                color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                sx={{
                  textTransform: 'none!important',
                  fontFamily: 'Inter'
                }}
              >
                Cancelar
              </Button>
              <LoadingButton
                loading={loading}
                onClick={onSubmit}
                variant="contained"
                style={{
                  backgroundColor:
                    themeMode.mode === 'light'
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.secondary.main}`,
                  color: loading
                    ? themeMode.mode === 'light'
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.secondary.main}`
                    : themeMode.mode === 'light'
                    ? `${theme.palette.text.white}`
                    : `${theme.palette.text.black}`
                }}
                sx={{
                  textTransform: 'none!important',
                  fontFamily: 'Inter'
                }}
              >
                Criar
              </LoadingButton>
            </div>
          </Box>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default ModalCreateBusiness;
